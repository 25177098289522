// Importaciones Externas!
import React from "react";
import {Slider as SliderMui,FormLabel} from "@mui/material";
import { PropsElement } from "../../props";

interface SliderState {
    value: number
    error?: string
}

// Componente Slider!
class Slider extends React.Component<PropsElement> {
    state: SliderState = {value: 0}
    min: number = 0;
    max: number = 100;
    
    constructor(props: PropsElement) {
        super(props);

        if(typeof props.value === "number") this.state = props.value;
        if(typeof props.min === "number") this.min = props.min;
        if(typeof props.max === "number") this.max = props.max;

        this.HandlerOnChange = this.HandlerOnChange.bind(this)
    }

    HandlerOnChange(event: any) {
        let props = this.props;
        let value: number = Number(event.target.value);

        this.setState({value});
        if(props.onChange) props.onChange(value, this.state.error);
    }

    render() {
        let props = this.props;
        let state = this.state;

        // Renderizamos el componente!
        return (
            <React.Fragment>
                <FormLabel>{props.display}</FormLabel>
                <SliderMui
                    name={props.name}
                    value={state.value}
                    disabled={props.disabled}
                    // autoFocus={props.focus}
                    className={props.className}
                    id={props.id}
                    onClick={props.onClick}
                    onChange={this.HandlerOnChange}
                    min={this.min}
                    max={this.max}
                    step={props.step}
                    // required={props.required}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                />
            </React.Fragment>
        )
    }
}


export default Slider; // Exportacion!