


function TimeText(date: Date) {
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let timeType = hours >= 11 && hours < 23 ? "PM" : "AM";

    if(hours >= 12) hours -= 12;
    hours++;

    return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0") + " " + timeType;
}

export default TimeText