import React from "react";
import "./Element.css";

import Input from "../Elements/Input";
import ButtonElement from "../Elements/Button";
import Checkbox from "../Elements/Checkbox"
import Phone from "../Elements/Phone"
import Radio from "../Elements/Radio"
import Rating from "../Elements/Rating"
import Select from "../Elements/Select"
import Slider from "../Elements/Slider"
import Switch from "../Elements/Switch"
import Group from "../Elements/Group"
import { PropsElement } from "../props";
import CitesComponent from "../Elements/Cites";

interface PropsElementTemplate extends PropsElement {
    render: Function
}

function loadMetadata(text: string) {
    let metadata: Array<any> = [];

    try {
        let loaded_metadata = require("../JSON/" + text + ".json")
        
        // console.log("Metadata cargada correctamente!");
        metadata = loaded_metadata;
    }
    catch(e: any) {
        // console.warn(e)
    }

    return metadata;
}


class Element extends React.Component<PropsElementTemplate> {
    ref: React.RefObject<any> = React.createRef()
    
    constructor(props: PropsElementTemplate) {
        super(props)
        
        this.HandlerOnChange = this.HandlerOnChange.bind(this)
    }
    
    HandlerOnChange(value: any, nameOrError: any, error: string) {
        if(typeof this.props.onChange === "function") {
            if(this.props.type === "group") this.props.onChange(value, nameOrError, error);
            else {
                this.props.onChange(value, this.props.name, nameOrError);
            }
        }
    }

    componentDidUpdate(): void {
        if(this.props.focus) this.ref.current.focus()
    }
    
    render() {
        let props = this.props;
        let metadata: any;
    
        let className = "FM-element";
    
        switch(props.align) {
            case "left":
            case "right":
            case "center":
                className += " FM-align-" + props.align;
                break;
            case "stretch":
            case undefined:
                // ALINEAMIENTO POR DEFECTO!
                break;
            default:
                console.warn(`No existe el alineamiento "${props.align}"`);
                break;
        }

        if(typeof props.metadata === "string") metadata = loadMetadata(props.metadata)
        // if(
        //     typeof props.options === "string" && 
        //     metadata instanceof Array && 
        //     metadata[0] instanceof Object &&
        //     metadata[0].templateName !== undefined &&
        //     metadata[0].metadata !== undefined
        // ) {
        //     let index = metadata.findIndex((meta: any) => (meta.templateName === props.options))

        //     if(index !== -1) {
        //         metadata = {options: metadata[index].metadata}
        //     }
        // }
        if(metadata instanceof Array) {
            let index = metadata.findIndex((meta: any) => (meta.templateName === props.loadMetadata))

            if(index !== -1) {
                const {templateName, ...metadata_load} = metadata[index];
                metadata = metadata_load;
            }
            else metadata = {};
        }
        else if(!(metadata instanceof Object)) {
            metadata = {} // objeto vacio!
        }
        
        return (
            <div className={className}>
                <div className="FM-element-container" ref={this.ref}>
                    <props.render {...props} onChange={this.HandlerOnChange} {...metadata} />
                </div>
            </div>
        )
    }
}

export default function GenerateElement(props: PropsElement) : JSX.Element | null {
    let Render: Function = () => {};

    switch(props.type) {
        case "input":
        case "date":
        case "time":
        case "number":
        case "money":
        case "email":
            Render = Input;
            break;
        case "button":
        case "submit":
            Render = ButtonElement;
            break;
        case "checkbox":
            Render = Checkbox;
            break;
        case "phone":
            Render = Phone;
            break;
        case "radio":
            Render = Radio;
            break;
        case "rating":
            Render = Rating;
            break;
        case "select":
            Render = Select;
            break;
        case "slider":
            Render = Slider;
            break;
        case "switch":
            Render = Switch;
            break;
        case "group":
            Render = Group;
            break;
        case "cites":
            Render = CitesComponent;
            break;

        case "none": // No renderizar nada!
            break;
            
        // en caso de no encontrar un tipo apropiado?
        default:
            console.warn(`No existe un elemento de tipo "${props.type}"`); // No renderizar nada!
            break;
        
    }
    return <Element {...props} render={Render} />
}