import React from "react"
import "./Taps.css"

interface Data {
    value?: any
    setValue?: Function
}

const TapsContext = React.createContext<any>(null);
const TapsContextChange = React.createContext<Function>(() => {});

interface TapsItemProps {
    children?: any
    value?: any
}

class TapItem extends React.Component<TapsItemProps, any, any> {
    static contextType = TapsContext;
    
    render() {
        let value = this.props.value;
        let className = "FM-tap-item";

        if(this.context === value) className += " FM-selected";
        
        return (
            <TapsContextChange.Consumer>
                {(fun: Function) => {
                    return (
                        <button type="button" className={className} onClick={() => fun(value)}>
                            {this.props.children}
                        </button>
                    )
                }}
            </TapsContextChange.Consumer>
        )
    }
}

interface TapsProps extends TapsItemProps {
    onChange?: any
    defaultValue?: any
}

class Tap extends React.Component<TapsProps, Data> {
    state: Data;
    
    constructor(props: TapsProps) {
        super(props)

        this.HandlerChange = this.HandlerChange.bind(this)

        this.state = {
            value: props.defaultValue,
            setValue: this.HandlerChange.bind(this)
        }
    }

    HandlerChange(value: any) {
        this.setState({value})
        if(typeof this.props.onChange === "function") this.props.onChange(value)
    }

    render() {
        return (
            <div className="FM-tap">
                <TapsContext.Provider value={this.props.value !== undefined ? this.props.value : this.state.value}>
                    <TapsContextChange.Provider value={this.HandlerChange}>
                        {this.props.children}
                    </TapsContextChange.Provider>
                </TapsContext.Provider>
            </div>
        )
    }
}

export { Tap, TapItem};