// Importaciones Externas!
import React from "react";
import {Rating as RatingMui, FormLabel} from "@mui/material";
import { PropsElement } from "../../props";

interface StateRating {
    value: 0,
    error?: string
}

class Rating extends React.Component<PropsElement> {
    state: StateRating = {value: 0}
    
    constructor(props: PropsElement) {
        super(props);

        this.state = {
            value: typeof props.value === "number" ? props.value : 0
        }

        this.HandlerOnChange = this.HandlerOnChange.bind(this);
    }

    HandlerOnChange(event: React.ChangeEvent<any>) {
        let props = this.props;
        let value: number = Number(event.target.value);

        this.setState({value})
        if(typeof props.onChange === "function") props.onChange(value, this.state.error);
    }

    render() {
        let props = this.props;
        let state = this.state;

        return (
            <div style={{width:"100%",justifyContent:"space-between",display:"flex"}}>
                <FormLabel>{props.display}</FormLabel>
                <RatingMui
                    name={props.name}
                    value={state.value}
                    disabled={props.disabled}
                    className={props.className}
                    id={props.id}
                    onClick={props.onClick}
                    onChange={this.HandlerOnChange}
                    size="large"
                />
            </div>
        )
    }
}

export default Rating; // Exportacion