// Importaciones Externas
import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";
import { PropsElement } from "../../props";

interface StateCheckbox {
    value: boolean
    error?: string // es el msg del error, SOLO SI EXISTE!
}

class CheckBox extends React.Component<PropsElement, StateCheckbox> {
    constructor(props: PropsElement) {
        super(props);

        this.state = {
            value: Boolean(props.value)
        }

        this.HandlerOnChange = this.HandlerOnChange.bind(this);
    }

    HandlerOnChange() {
        let props = this.props;
        
        this.setState((state) => {
            let value = !Boolean(state.value);

            if(typeof props.onChange === "function") props.onChange(value, this.state.error)

            // props.onChange(value);
            return {value};
        })
    }
    
    render() {
        let props = this.props;
        let state = this.state;

        let sx: any = {
                color: props.color,
                '&.Mui-checked': {
                  color: props.color
                },
            };
        
        return (
            <FormControlLabel
                label={props.display}
                control={
                    <Checkbox
                        name={props.name}
                        checked={Boolean(state.value)}
                        disabled={props.disabled}
                        autoFocus={props.focus}
                        className={props.className}
                        id={props.id}
                        onClick={props.onClick}
                        onChange={this.HandlerOnChange}
                        required={props.required}
                        sx={sx}
            />}/>
        )
    }
}

// // Componente CheckBox
// function CheckboxElement({template})
// {
//     /* Se verifica y/o se guardan los cambios del componente */
//     function VerifyElement(event, index) {
//         // Actualizamos el valor a partir del mismo, invitiendolo
//         let new_value = template.options[index].value = !(template.options[index].value);
//         // Ejecutamos la funcion del template, solo si existe!
//         if(template.onChange) template.onChange(new_value, template.options[index].name);
//     }

//     // Si el template no posee un valor, le asignamos uno por defecto!
//     if(typeof template.value === "boolean") template.value = false;

//     // Estilo del Componente Checkbox (Lo utiliza Material UI)
//     let sx = {
//         color: template.color,
//         '&.Mui-checked': {
//           color: template.color
//         },
//     };

//     // Creamos un array de renderización de checkboxes
//     let checkboxes = template.options.map((option, index) => (
//             <FormControlLabel
//                 key={"$CHECK"+option.name+option.value+index}
//                 label={option.display}
//                 control={
//                     <Checkbox
//                         name={option.name}
//                         defaultChecked={option.value}
//                         disabled={template.disabled}
//                         autoFocus={template.focus}
//                         className={template.className}
//                         id={template.id}
//                         onClick={template.onClick}
//                         onChange={(event) => {VerifyElement(event,index)}}
//                         required={template.required}
//                         sx={sx}
//             />}/>
//         ));

//     // Retornamos la renderizacion de un contenedor con la lista de Checkboxes
//     return (
//         <div>
//             {template.display && <FormLabel>{template.display}</FormLabel>}
//             <div className="FMform-checkbox-container">
//                 {checkboxes}
//             </div>
//         </div>
//     )
// }

export default CheckBox // Exportacion!