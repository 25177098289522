// Importaciones Externas
import React from "react";
import CitesComponent from "../../ElementComponents/Cites";
import { DateText, DateTextForm, InterpretTextDate } from "../../LIB/dateLib";
import { PropsElement } from "../../props";

interface StateCites {
    error?: string
}

class Cites extends React.Component<PropsElement, StateCites> {
    values: string = ""

    constructor(props: PropsElement) {
        super(props);

        this.state = {};
        this.HandlerOnChange = this.HandlerOnChange.bind(this);
    }    
    
    HandlerOnChange(date: Date) {
        if(typeof this.props.onChange === "function") {
            this.props.onChange(DateTextForm(date), this.state.error);
        }
    }
    
    render() {
        let props = this.props;

        return (
            <CitesComponent 
                defaultValue={InterpretTextDate(props.value)}
                min={InterpretTextDate(props.min as string | undefined)}
                max={InterpretTextDate(props.max as string | undefined)}
                timeMin={props.timeMin}
                timeMax={props.timeMax}
                onChange={this.HandlerOnChange}
            />
        )
    }
}

export default Cites; // Exportacion