import React from "react";
import FormPage from "../Form.Page/Form.Page";
import Interpol from "../Form.Page/interpol.element";
import { PropsElement, PropsFormPage, PropsFormStruct } from "../props";
import "./Form.Struct.css";

// function FormFinished() {
//     return (
//         <div className="FM-form-page">
//             <div className="FM-form-page-title">Finalización</div>
//             <div className="FM-form-page-subtitle">ha sido todo. Ya puedes cerrar esta página!</div>
//         </div>
//     )
// }

function Background(props: any) {
    let style: any = {}

    if(typeof props.background === "string") {
        style.backgroundImage = `url(${props.background})`;
    }
    else {
        style.backgroundImage = "linear-gradient(var(--color), white)";
    }

    style["--color"] = props.color;

    return (
        <div className="FM-form-struct" style={style}>
            {props.children}
        </div>
    )
}

interface PropsFormStruct_local {
    template: PropsFormStruct
    onFinish?: Function
}

class FormStruct extends React.Component<PropsFormStruct_local> {
    template: PropsFormStruct = {pages:[]};
    pages: Array<PropsFormPage> = [];
    pageLocal: PropsFormPage = {};
    pageSelected?: PropsFormPage;
    general: PropsElement = {};

    index: number = 0;
    finished: boolean = false;
    values: Array<any> = [];
    metadata: PropsFormPage["metadata"];
    jump: string | undefined = undefined;
    history: Array<number> = [];

    constructor(props: PropsFormStruct_local) {
        super(props);

        this.LoadPage = this.LoadPage.bind(this);
        this.NextPage = this.NextPage.bind(this);
        this.BackPage = this.BackPage.bind(this);
        this.HandlerOnChange = this.HandlerOnChange.bind(this);
        this.LoadPageFinished = this.LoadPageFinished.bind(this);

        this.saveHistory = this.saveHistory.bind(this)
        this.restoreHistory = this.restoreHistory.bind(this)
        
        if(props.template instanceof Object) this.template = props.template;
        if(this.template.general instanceof Object) this.general = this.template.general;
        if(this.template.pages instanceof Array) this.pages = this.template.pages;
        
        this.LoadPage(this.index);
    }

    LoadPageFinished() {
        this.pageLocal.title = "Encuesta finalizada";
        this.pageLocal.subtitle = "Gracias por tu tiempo";
        this.pageLocal.type = "none";
        this.pageLocal.next = undefined;
        this.pageLocal.back = undefined;
    }

    LoadPage(index: number) {
        this.index = index;
        
        if(this.pages[index] instanceof Object) this.pageLocal = this.pages[index];
        else this.pageLocal = {};

        let pageLocal = this.pageSelected = this.pageLocal;
        
        // if(this.metadata !== undefined) {
        //     // Verificamos si las metadatas es un array de metadatas!
        //     if(this.metadata instanceof Array && this.metadata[0] instanceof Object && this.metadata[0].metadata !== undefined)
        //     {
        //         pageLocal.metadata = this.metadata[0].metadata;
        //     }
        //     else pageLocal.metadata = this.metadata;
        // }
        if(this.metadata !== undefined) pageLocal.metadata = this.metadata;
        
        {// Obtener valores previos!
            let i = this.values.findIndex((value:any) => (value.key === pageLocal.name));
            
            if(i !== -1) {
                pageLocal.value = this.values[i].value;
            }
        }
        
        if((this.finished = (this.index >= this.pages.length))) {
            this.LoadPageFinished();
            
            if(typeof this.props.onFinish === "function")
            this.props.onFinish(this.values);
        }
        
        this.pageLocal = Interpol({...this.general, ...this.pageLocal}, this.values);
    }

    InitMetadata() {
        let index:number = this.pages.findIndex((page: any) => (this.pageSelected === page));

        this.jump = this.pageLocal.jump;
        if(this.pageLocal.resend) {
            // Se pueden cargar las metadatas locales!
            this.metadata = this.pageLocal.metadata;
        }
        else this.metadata = undefined;

        if(index !== -1) {
            let obj = this.pages[index];
            index = this.values.findIndex(value => (value.key === obj.name));

            if(index !== -1) {
                let value = this.values[index];

                if(obj.type === "radio" || obj.type === "select") {
                    if(obj.options instanceof Array) {
                        index = obj.options.findIndex((option: any) => (option.value === value.value))

                        if(index !== -1) {
                            let option = obj.options[index];

                            if(option.metadata !== undefined) {
                                this.metadata = option.metadata;
                            }
                            if(option.jump !== undefined) this.jump = option.jump;
                        }
                    }
                }
            }
        }
        

        this.pageLocal.metadata = this.metadata;
    }

    evalJump(jump?: string) {
        if(jump === "finish") {
            return 1000000; // indice excesivo para forzar la finalizacion
        }
        if(typeof jump === "string") {
            // console.log("EVAL: " + jump);
            let index = this.pages.findIndex((page:any) => ((page.id) === jump));

            if(index !== -1) {
                // console.log("JUMP:" + index);
                return index;
            }
            else {
                index = this.pages.findIndex((page: any) => (page.name === jump))
                
                if(index !== -1) {
                    // console.log(this.pages[index])
                    // console.log("JUMP:" + index);
                    return index;
                }
            }
        }
        return this.index + 1;
    }

    saveHistory() {
        this.history.push(this.index);
    }

    restoreHistory() {
        this.metadata = undefined;
        if(this.history.length > 0) {
            this.LoadPage(Number(this.history.pop()))
        }
    }

    NextPage() {
        this.InitMetadata();
        this.saveHistory();
        this.LoadPage(this.evalJump(this.jump));
        this.forceUpdate();
    }

    BackPage() {
        this.restoreHistory()
        this.forceUpdate();
    }

    HandlerOnChange(value:any, key:string) {
        let index:number = this.values.findIndex((value:any) => (value.key === key));

        if(index !== -1) {
            this.values[index].value = value;
        }
        else {
            this.values.push({key, value});
        }
    }
    
    render() {
        return (
            <Background {...this.pageLocal}>
                <FormPage key={this.index} {...this.pageLocal} onNext={this.NextPage} onBack={this.BackPage} onChange={this.HandlerOnChange}  />
            </Background>
        )
    }
}

export default FormStruct;