import { PropsElement } from "../props";

interface value {
    key: string,
    value: any
}


function SetValueToAll(element: PropsElement, values: Array<value> | any) {
    if(element instanceof Object) {
        if(element.type === "group") {
            if(element.main instanceof Array) {
                element.main.forEach((e: any) => SetValueToAll(e, values))
            }
        }
        else {
            let index;

            if(values instanceof Array) {
                index = values.findIndex(value => (value.key === element.name))

                if(index !== -1) {
                    element.value = values[index].value;
                }
            }
            else if(values instanceof Object && values[String(element.name)] !== undefined) {
                element.value = values[String(element.name)]
            }
        }
    }
    return element;
}

export default SetValueToAll;