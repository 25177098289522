// Importaciones Externas
import React from "react";
import TextField from "@mui/material/TextField";
import { PropsElement } from "../../props";
import { DateTextForm } from "../../LIB/dateLib";

function TimeSimple(date: Date) {
    return (
        String(date.getHours()).padStart(2, "0") + ":" +
        String(date.getMinutes()).padStart(2, "0")
    )
}

function InterpretDate(text?: string): string {
    if(text === "now") {
        return DateTextForm(new Date())
    }
    else if(typeof text === "string") {
        console.log(text)
        return DateTextForm(new Date(text))
    }
    return "";
}

function InterpretTime(text?: string) {
    if(text === "now") {
        return TimeSimple(new Date())
    }
    return "";
}

interface StateInput {
    value: string,
    error?: string
}

// Componente de Entrada
class InputElement extends React.Component<PropsElement> {
    state: StateInput = {value: ""};

    constructor(props: PropsElement) {
        super(props);

        // estado inicial
        // this.state = {};

        if(typeof props.value === "string") {
            if(props.type === "date") {
                this.state.value = InterpretDate(props.value);
                if(typeof props.onChange === "function") {
                    props.onChange(this.state.value)
                }
            }
            else if(props.type === "time") {
                this.state.value = InterpretTime(props.value)
                if(typeof props.onChange === "function") {
                    props.onChange(this.state.value)
                }
            }
            else this.state.value = this.props.value as string;
        }

        this.HandlerOnChange = this.HandlerOnChange.bind(this);
    }

    HandlerOnChange(event: React.ChangeEvent<any>) {
        let props:any = this.props;
        let value: string = event.target.value;

        if(this.props.type === "money") {
            value = value.replace(/\D/g, "");
            {
                let zero = value.match(/0+/);

                if(value.indexOf(String(zero)) === 0) value = value.replace(String(zero), "");
            }
            value = value.padStart(3, "0");

            value = value.slice(0, value.length-2) + "," + value.slice(-2);
            let length = value.length - 6;

            while(length >= 1) {
                value = value.slice(0, length) + "." + value.slice(length, value.length);

                length -= 3;
            }
        }

        this.setState({value}) // se actualiza el estado

        // se ejecuta onChange parametro
        if(typeof props.onChange === "function") {
            props.onChange(value, this.state.error)
        }
    }

    render() {
        let props = this.props;
        let state = this.state;

        // Renderizacion del componentee
        return (
            <TextField
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: props.color
                        }
                    },
                    "& label.Mui-focused": {
                        color: props.color
                    }
                }}
                name={props.name}
                value={state.value}
                label={props.display}
                disabled={props.disabled}
                autoFocus={props.focus}
                className={props.className}
                id={props.id}
                onClick={props.onClick}
                onChange={this.HandlerOnChange}
                // helperText={error ? template.error : template.help}
                placeholder={props.placeholder}
                // error={Boolean(error)}
                required={props.required}
                type={props.type === "input" || props.type === "money" ? "text" : props.type}
                multiline={props.multiline}
                rows={props.rows}
                autoComplete="off"
            />
        )
    }
}


export default InputElement; // Exportacion!