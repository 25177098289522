import React from "react";
import {Select as SelectMui, MenuItem, FormControl, InputLabel, SelectChangeEvent} from "@mui/material";
import { PropsElement } from "../../props";

interface StateSelect {
    value: string
    error?: string
}

class Select extends React.Component<PropsElement> {
    options: Array<any> = [];
    optionElements: Array<JSX.Element> = [];
    state: StateSelect = {value: ""}
    
    constructor(props: PropsElement) {
        super(props);
        this.HandlerOnChange = this.HandlerOnChange.bind(this);

        if(props.options instanceof Array) this.options = props.options;

        if(typeof props.value === "string") this.state = {value: props.value}

        this.optionElements = this.options.map((option,index) => {
            // Verificamos si no hay un valor de display
            if(typeof option.display !== "string") option.display = option.value; // asignamos valor a display

            // Renderizamos una opcion
            return (
                <MenuItem key={"$SELECT-"+props.name+option.value+option.display} value={String(index)}>
                    {option.display}
                </MenuItem>
            );
        });
    }

    HandlerOnChange(event: SelectChangeEvent<any>) {
        let props: PropsElement = this.props as any;
        let value: number = Number(event.target.value);
        let option = this.options[value];

        this.setState({value: option.value});
        if(typeof props.onChange === "function") props.onChange(option.value, this.state.error);
    }
    
    render() {
        let props = this.props;
        let state = this.state;
        
        let sxSelect = {
            "& label.Mui-focused": {
                color: props.color
            },
            "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                borderColor: props.color
            }
        }

        let value: number | string = this.options.findIndex(option => (option.value === state.value));

        if(value === -1) value = "";
        
        return (
            <FormControl sx={sxSelect}>
                <InputLabel>{props.display}</InputLabel>
                <SelectMui
                    name={props.name}
                    value={value}
                    label={props.display}
                    disabled={props.disabled}
                    autoFocus={props.focus}
                    className={props.className}
                    id={props.id}
                    onClick={props.onClick}
                    onChange={this.HandlerOnChange}
                >
                    {this.optionElements}
                </SelectMui>
            </FormControl>
        )
    }
}

export default Select; // Exportación