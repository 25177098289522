// Importaciones Externas!
import React from "react";
import {RadioGroup, FormControlLabel, FormLabel, Radio as RadioMui} from "@mui/material";
import { PropsElement } from "../../props";

interface RadioState {
    error?: string
    value: any
}

// Componente Grupo de radioButtons
class Radio extends React.Component<PropsElement, RadioState> {
    options: Array<any> = [];
    
    constructor(props: PropsElement) {
        super(props);

        this.state = {
            value: props.value
        }

        if(props.options instanceof Array) this.options = props.options;

        this.HandlerOnChange = this.HandlerOnChange.bind(this);
    }

    HandlerOnChange(event: React.ChangeEvent<any>) {
        let props = this.props;

        let index = Number(event.target.value);
        let option = this.options[index];

        this.setState({value: option.value});

        if(typeof props.onChange === "function") props.onChange(option.value, this.state.error);
    }
    
    render() {
        let props: PropsElement = this.props as any;
        let state = this.state as any;

        let index: number | string = this.options.findIndex(option => (option.value === state.value));

        // Se asigna un array de renderizaciones, de las distintas opciones (radio button)
        let elementsRadio = this.options.map((option, index) => {
                // Verificamos si posee un valor válido
                if(typeof option.value === "string") {
                    // Asignamos el valor del valor, a la visualizacion (si no está definida!)
                    if(typeof option.display !== "string") option.display = option.value;

                    // Renderizamos correctamente el radio button
                    return (
                        <FormControlLabel 
                            key={"$RADIO-"+option.name+option.display+index} 
                            value={index} 
                            label={option.display} 
                            control={<RadioMui sx={{color: props.color,'&.Mui-checked':{color: props.color}}} required={props.required} />} 
                        />
                    );
                }
                return null; // en caso q no se pueda renderizar
            })

        if(index === -1) index = "";

        // Renderizamos el contenedor de radio buttons, con su respectivos radio buttons!
        return (
            <div>
                <FormLabel>{props.display}</FormLabel>
                <RadioGroup
                    name={props.name}
                    value={index}
                    className={props.className}
                    id={props.id}
                    onClick={props.onClick}
                    onChange={this.HandlerOnChange}
                >
                    {elementsRadio}
                </RadioGroup>
            </div>
        )
    }
}

export default Radio; // Exportacion General