
function Interpol(obj: any, values: Array<any> | any) {
    if(obj instanceof Object || obj instanceof Array) {
        for(let key in obj) {
            obj[key] = Interpol(obj[key], values);
        }
    }
    else if(typeof obj === "string") {
        let vars = obj.match(/%[a-z]+%/ig);
        let new_text = obj;
        
        if(vars !== null) {
            for(let _var of vars) {
                let varName = _var.slice(1, -1);
                let replace = "";
                let value: any;
                
                if(values instanceof Array) {
                    value = values.find((value: any) => (value.key === varName));
                }
                else if(values instanceof Object) {
                    value = {value: values[varName]};
                }

                if(value) replace = String(value.value);
                
                new_text = new_text.replace(_var, replace)
                // console.log(_var + " => " + replace)
            }
        }

        return new_text
    }
    return obj;
}

export default Interpol;