// Importaciones Externas
import React from 'react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { PropsElement } from '../../props';

// Componente de entrada (Numero Celular)

interface StatePhone {
  value: string
}

class Phone extends React.Component<PropsElement> {
  state: StatePhone = {value: ""}
  
  constructor(props:PropsElement) {
    super(props);

    this.HandlerOnChange = this.HandlerOnChange.bind(this);
    
    this.state = {
      value: typeof props.value === "string" ? props.value : ""
    }
  }

  HandlerOnChange(value:string) {
    let props = this.props;
    let error = !matchIsValidTel(value) ? "Número Inválido!" : undefined;


      this.setState({value, error});
  
      if(typeof props.onChange === "function") props.onChange(value, error); 
  }

  render() {
    let props = this.props;
    let state = this.state;
  
    let sx = {
      "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
              borderColor: props.color
          }
      },
      "& label.Mui-focused": {
          color: props.color
      }
    }
  
    // Renderización del elemento
    return (
      <MuiTelInput autoComplete="false" sx={sx} value={state.value} onChange={this.HandlerOnChange} continents={['NA','SA']} />
    )
  }
}

export default Phone; // Exportacion!