// Importaciones Externas
import React from "react";
import GenerateElement from "../../Form.Page/Element";
import { PropsElement } from "../../props";
import "./index.css";

class Group extends React.Component<PropsElement> {
    render() {
        let props = this.props;
        let elements: Array<JSX.Element> | undefined;

        if(props.main instanceof Array) {
            elements = props.main.map((child:any, index:number) => <GenerateElement {...{...props, ...child}} key={index} />);
        }
        
        return (
            <div className={"FM-element-group" + " FM-" + (props.orientation === "vertical" ? "vert" : "horz")}>
                {elements}
            </div>
        )
    }
}

export default Group; // Exportaciones