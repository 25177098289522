import React from 'react';
import './App.css';
// import FormGenerator from './Form.Generator/Form.Generator';
import FormStruct from './Form.Struct/Form.Struct';
import json from "./JSON/index.json";

import axios from "axios"
import FormGenerator from './Form.Generator/Form.Generator';
// import ExtractParams from './LIB/url';

// console.log(ExtractParams(document.location.href))

function SendData(data: Array<any>) {
  console.log("Encuesta finalizada: ")
  let query_fields = [];
  let query_values = [];

  for (let index = 0; index < data.length; index++) {
    for(let key in data[index]){
      if (key === "key") { query_fields.push(data[index][key]) }
      if (key === "value") { query_values.push(`'${data[index][key]}'`) }
    }
    
  }
  let query = `INSERT INTO sbSurveys(${query_fields}) VALUES(${query_values})`;

  console.log(query)
  const DataQuery = {
    query: `
    mutation{
      DataEncrypy(Data:"${query}")
    }`,
  }

  axios({
    url:'https://backend-fp.thomas-talk.me/',
    data: DataQuery,
    method:'post'
  })
  .then((result: any)=>{
    //console.log('Datos enviados para encriptar')
    //console.log(result.data.data.DataEncrypy)
    redirectTo(result.data.data.DataEncrypy)
  })
  .catch((error: any)=>{
    console.error(error)
  })

  // if(typeof (json as any).sendTo === "string") {
  //   axios({
  //     method: 'post', 
  //     url: (json as any).sendTo, // 'https://api.thomas-talk.me/RegisterSolicitudDocument',
  //     data: query
  //   })
  //   .then((res: any) => {
  //     console.log("Datos Enviados correctamente!")
  //     console.log(res)
  //   })
  //   .catch((err: any) => console.error(err))
  // }
}

function redirectTo(encryptedData: any){
  axios({
    method: 'post', 
    url: 'https://api.thomas-talk.me/SendAnyData/4p1_[51t3m4s]_f186@th0m4s',
    data: {Query:encryptedData}
  })
  .then((result: any) => {
    //console.log("Datos encriptados enviados correctamente")
    //console.log(result)
  })
  .catch((error: any) => {
    console.error(error)
  })
}

function App() {
  return (
    // <div>H</div>
    <div className='app'>
      {/* <FormGenerator /> */}
      <FormStruct template={json} onFinish={SendData} />
    </div>

  );
}

export default App;
