const monthName = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
]

const nameWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado"
]

const monthCount = [
    31, // enero
    28, // febrero
    31, // marzo
    30, // abril
    31, // mayo
    30, // junio
    31, // julio
    31, // agosto
    30, // septiembre
    31, // octubre
    30, // noviembre
    31  // diciembre
];

function MonthName(date: Date) {
    return monthName[date.getMonth()];
}

function DateText(date: Date) {
    return `${date.getDate()}, ${MonthName(date)} - ${date.getFullYear()}`
}

function DateTextForm(date: Date) {
    return (
        String(date.getFullYear()).padStart(2, "0") + "-" +
        String(date.getMonth()+1).padStart(2, "0") + "-" +
        String(date.getDate()).padStart(2, "0")
    )
}

function CompareDate(date_a: Date, date_b: Date) {
    if(date_a instanceof Date && date_b instanceof Date) {
        // la instancia actual es menor que el parametro?
        if(
            (date_a.getDate() < date_b.getDate() && date_a.getMonth() <= date_b.getMonth() && date_a.getFullYear() <= date_b.getFullYear()) ||
            (date_a.getMonth() < date_b.getMonth() && date_a.getFullYear() <= date_b.getFullYear()) ||
            (date_a.getFullYear() < date_b.getFullYear())
        ) {
            return -1; // ES MENOR!
        }
        // la instancia actual es mayor que el parametro?
        else if(
            (date_a.getDate() > date_b.getDate() && date_a.getMonth() >= date_b.getMonth() && date_a.getFullYear() >= date_b.getFullYear()) ||
            (date_a.getMonth() > date_b.getMonth() && date_a.getFullYear() >= date_b.getFullYear()) ||
            (date_a.getFullYear() > date_b.getFullYear())
        ) {
            return 1; // ES MAYOR!
        }
        return 0; // ES IGUAL!!!
    }
}

function CompareFullDate(date_a: Date, date_b: Date) {
    let result = CompareDate(date_a, date_b);

    if(date_a instanceof Date && date_b instanceof Date && result === 0) {
        // Comparamos la hora
        if(
            ( date_a.getMinutes() > date_b.getMinutes() && date_a.getHours() >= date_b.getHours()) ||
            ( date_a.getHours() > date_b.getHours() )
        ) {
            result = 1; // ES MAYOR!!
        }
        else if(
            ( date_a.getMinutes() < date_b.getMinutes() && date_a.getHours() <= date_b.getHours()) ||
            ( date_a.getHours() < date_b.getHours() )
        ) {
            result = -1; // ES MENOR!
        }
    }
    return result;
}

function InterpretTextDate(text: string | undefined): Date | undefined {
    if(text === "now") {
        return new Date()
    }
    else if(typeof text === "string") {
        return new Date(text)
    }
    return;
}

export {
    MonthName,
    DateText,
    CompareDate,
    CompareFullDate,
    InterpretTextDate,
    DateTextForm
}