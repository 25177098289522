// Importaciones Externas!
import React from "react";
import {Switch as SwitchMui, FormControlLabel} from "@mui/material";
import { PropsElement } from "../../props";

interface StateSwitch {
    value: boolean
    error?: string
}

// Componente Switch
class Switch extends React.Component<PropsElement> {
    state: StateSwitch = {value: false}
    
    constructor(props: PropsElement) {
        super(props);

        this.state = {
            value: Boolean(props.value)
        }

        this.HandlerOnChange = this.HandlerOnChange.bind(this);
    }

    HandlerOnChange() {
        let props = this.props;
        
        this.setState((state:any) => {
            let value = !Boolean(state.value);

            if(typeof props.onChange === "function") props.onChange(value, this.state.error)

            // props.onChange(value);
            return {value};
        })
    }
    
    render() {
        let props = this.props;
        let state = this.state;
        // Renderizamos el elemento
        return (
            <FormControlLabel 
                className="FMform-switch-label" 
                label={props.display} 
                // labelPlacement="start"
                control={
                    <SwitchMui
                        name={props.name}
                        checked={state.value}
                        disabled={props.disabled}
                        autoFocus={props.focus}
                        className={props.className}
                        id={props.id}
                        onClick={props.onClick}
                        onChange={this.HandlerOnChange}
                        required={props.required}
                    />}
            />
        )
    }
}


export default Switch; // Exportaciones Generales!