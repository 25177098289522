import React from "react"
import { CompareDate, CompareFullDate, DateText } from "../../LIB/dateLib"
import TimeText from "../../LIB/timeLib"
import { Tap, TapItem } from "../Taps/Taps"
import "./index.css"

interface CitesComponentProps {
    timeMin?: number
    timeMax?: number
    min?: Date
    max?: Date
    defaultValue?: Date
    onChange?: Function
}

interface CitesComponentState {
    display: Date
    selected: Date | null
    typeFilter: "mañana" | "tarde",
}

class CitesComponent extends React.Component<CitesComponentProps, CitesComponentState> {
    state: CitesComponentState = {
        typeFilter: "mañana",
        display: new Date(),
        selected: null
    }
    body: Array<JSX.Element> = []
    timeMin: number = 0
    timeMax: number = 24
    min: Date = new Date(0)
    max: Date = new Date(2100,11)
    
    constructor(props: CitesComponentProps) {
        super(props)

        this.BuildBody = this.BuildBody.bind(this)
        this.HandlerOnChange = this.HandlerOnChange.bind(this)
        this.HandlerLeft = this.HandlerLeft.bind(this)
        this.HandlerRight = this.HandlerRight.bind(this)
        this.handlerChangeFilter = this.handlerChangeFilter.bind(this)
        this.HandlerSelectSelected = this.HandlerSelectSelected.bind(this)

        if(typeof props.timeMin === "number") this.timeMin = props.timeMin;
        if(typeof props.timeMax === "number") this.timeMax = props.timeMax;
        if(props.min instanceof Date) this.min = props.min;
        if(props.max instanceof Date) this.max = props.max;
        if(props.defaultValue instanceof Date) this.state.selected = props.defaultValue;

        this.state.typeFilter = this.state.display.getHours() > 11 ? "tarde" : "mañana"
        
        this.VerifyDisplay();
        this.BuildBody(this.state) // Se ejecuta la funcion!
    }

    handlerChangeFilter(typeFilter: "mañana" | "tarde") {
        this.setState({typeFilter})
    }
    
    HandlerOnChange(selected: Date) {
        this.setState({selected})
        if(typeof this.props.onChange === "function") this.props.onChange(selected);
    }

    VerifyDisplay() {
        let time = new Date(Number(this.min))
        time.setHours(11);
        
        if(CompareDate(this.state.display, this.min) === -1) {
            this.state.display = new Date(Number(this.min));
        }
        else if(CompareDate(this.state.display, this.min) === 0) {
            if(this.min.getHours() >= this.timeMax) {
                this.state.display = new Date(Number(this.min));
                this.state.display.setDate(this.state.display.getDate() + 1);
                this.state.typeFilter = "mañana"
            }
            else if(this.min.getHours() > 11 && this.state.typeFilter === "mañana") {
                this.setState({typeFilter: "tarde"});
            }
        }
        if(CompareDate(this.state.display, this.max) === 1) {
            this.state.display = new Date(Number(this.max));
        }
    }

    HandlerLeft() {
        this.state.display.setDate(this.state.display.getDate() - 1);
        this.VerifyDisplay();
        this.setState({display: new Date(Number(this.state.display))})
    }

    HandlerRight() {
        this.state.display.setDate(this.state.display.getDate() + 1);
        this.VerifyDisplay();
        this.setState({display: new Date(Number(this.state.display))})
    }

    HandlerSelectSelected() {
        if(this.state.selected instanceof Date) {
            this.setState({
                display: new Date(Number(this.state.selected)),
                typeFilter: this.state.selected.getHours() >= 12 ? "tarde" : "mañana"
            })
        }
    }
    
    BuildBody(state: CitesComponentState) {
        let onChange = this.HandlerOnChange;
        let timeMin = this.timeMin;
        let timeMax = this.timeMax;
        this.body = []

        if(state.typeFilter === "mañana") { // 0 significa "MAÑANA"
            if(timeMax >= 12) timeMax = 11;
        }
        else { // En este caso deberia valer 1 y significa "TARDE"
            if(timeMin <= 12) timeMin = 11;
        }

        for(let time = timeMin; time < timeMax; time++) {
            let date = new Date(Number(state.display));
            let className = "FM-time";

            date.setHours(time)
            date.setMinutes(0);
            
            if((CompareFullDate(date, this.min) === -1) || CompareFullDate(date, this.max) === 1) continue
            
            if(state.selected instanceof Date && CompareFullDate(state.selected, date) === 0) {
                className += " FM-selected"
            }

            this.body.push(
                <button key={time} type="button" className={className} onClick={() => onChange(date)}>
                    {TimeText(date)}
                </button>
            )
        }
    }

    shouldComponentUpdate(nextProps: Readonly<CitesComponentProps>, nextState: Readonly<CitesComponentState>): boolean {
        let result: boolean = (this.state.display !== nextState.display || this.state.selected !== nextState.selected || this.state.typeFilter !== nextState.typeFilter)

        if(result) {
            this.BuildBody(nextState);
        }
        return result;
    }

    render() {
        return (
            <div className="FM-cites">
                <div className="FM-cites-top">
                    <button type="button" onClick={this.HandlerLeft}>{"<"}</button>
                    <div className="FM-cites-head">
                        {DateText(this.state.display)}
                    </div>
                    <button type="button" onClick={this.HandlerRight}>{">"}</button>
                </div>
                <Tap value={this.state.typeFilter} onChange={this.handlerChangeFilter}>
                    <TapItem value="mañana">Mañana</TapItem>
                    <TapItem value="tarde">Tarde</TapItem>
                </Tap>
                <div className="FM-cites-body" key={Number(this.state.display)}>
                    {this.body}
                </div>
                <div className="FM-cites-value" onClick={this.HandlerSelectSelected}>
                    {
                        this.state.selected instanceof Date ?
                        (TimeText(this.state.selected) + " - " + DateText(this.state.selected))
                        : "Selecciona una fecha"
                    }
                </div>
            </div>
        )
    }
}

export default CitesComponent